<template>
  <span>
    宿泊日から{{
      data.bookingFromMonths
    }}か月{{
      data.bookingFromDays
    }}日前
  </span>
</template>

<script>
export default {
  props:{
    data: Object
  }
}
</script>