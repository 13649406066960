<template>
  <v-dialog v-model="dialog">
    <div class="px-5 pb-8 pt-3">
      <div>
        <div class="d-flex justify-space-between mt-2">
          <h2>
            {{ title }} :
            {{ $t('corporateTicket.title') }}
          </h2>
          <div class="d-flex justify-space-end">
            <v-btn
              class="t-btn--prm mx-1"
              :to="
                '/customer-details/' +
                  bookingTicketTypeDetail.intendedClientId +
                  '?typeClient=' +
                  (bookingTicketTypeDetail.intendedClient &&
                    bookingTicketTypeDetail.intendedClient.type)
              "
              target="_blank"
              >顧客詳細</v-btn
            >
            <v-btn
              class="t-btn--prm mx-1"
              :disabled="!checkUser"
              @click="showTicketDetail()"
              >チケット詳細</v-btn
            >
            <v-btn class="t-btn--red-dark mx-1" @click="closeDialog">
              <v-icon class="mr-1" color="#FFFFFF"> mdi-close </v-icon>
              閉じる
            </v-btn>
            <v-btn
              class="t-btn--prm ml-1"
              :disabled="!checkUser"
              @click="closeDialog"
              >保存</v-btn
            >
          </div>
        </div>
      </div>
      <div class="my-5">
        <v-row>
          <v-col :cols="3" class="d-flex justify-center">
            <div class="table-item">
              <div>
                <span>{{ $t('corporateTicket.numberTicket') }} ：</span>
                <span> {{ bookingTicketTypeDetail.id }} </span>
              </div>
              <div>
                <span>{{ $t('corporateTicket.numberProblem') }} ：</span>
                <span>
                  {{
                    bookingTicketTypeDetail.computed &&
                      bookingTicketTypeDetail.computed.issuedCount
                  }}
                </span>
              </div>
              <div>
                <span>{{ $t('corporateTicket.name') }} ：</span>
                <span> {{ bookingTicketTypeDetail.name }} </span>
              </div>
              <div>
                <span>{{ $t('corporateTicket.basis') }} ：</span>
                <template v-if="bookingTicketTypeDetail.roomTypes">
                  <span>
                    {{
                      bookingTicketTypeDetail.roomTypes.length > 0 &&
                        getFacilityName(bookingTicketTypeDetail.roomTypes)
                    }}
                  </span>
                </template>
              </div>
              <div>
                <span>{{ $t('corporateTicket.roomTypeInfo') }} ：</span>
                <template v-if="bookingTicketTypeDetail.roomTypes">
                  <span>
                    {{
                      bookingTicketTypeDetail.roomTypes.length > 0 &&
                        bookingTicketTypeDetail.roomTypes
                          .map(item => item.roomType.name)
                          .join(' , ')
                    }}
                  </span>
                </template>
              </div>
              <div>
                <span>{{ $t('corporateTicket.date') }} ：</span>
                <template v-if="bookingTicketTypeDetail.availableFromDate">
                  <span>
                    {{
                      bookingTicketTypeDetail.availableFromDate | formatDate
                    }} ~ {{
                        bookingTicketTypeDetail.availableToDate | formatDate
                    }}</span
                  >
                </template>
              </div>
              <div>
                <span>{{ $t('corporateTicket.DayOfTheƯeek') }} ：</span>
                <span>
                  {{
                    removeLastCharacter(
                      (bookingTicketTypeDetail.availableMonday ? '月/' : '') +
                        (bookingTicketTypeDetail.availableTuesday
                          ? '火/'
                          : '') +
                        (bookingTicketTypeDetail.availableWednesday
                          ? '水/'
                          : '') +
                        (bookingTicketTypeDetail.availableThursday
                          ? '木/'
                          : '') +
                        (bookingTicketTypeDetail.availableFriday ? '金/' : '') +
                        (bookingTicketTypeDetail.availableSaturday
                          ? '土/'
                          : '') +
                        (bookingTicketTypeDetail.availableSunday ? '日/' : '') +
                        (bookingTicketTypeDetail.availableHoliday ? '祝/' : ''),
                      '/',
                    )
                  }}
                </span>
              </div>
              <div>
                <span>{{ $t('corporateTicket.ExclusionDate') }} ：</span>
                <template v-if="bookingTicketTypeDetail.exceptDates">
                  <span>
                    {{
                      bookingTicketTypeDetail.exceptDates.length > 0 ?
                        bookingTicketTypeDetail.exceptDates
                          .map(item => item.exceptDate.split('-').join('/'))
                          .join(' , ') : '無し'
                    }}
                  </span>
                </template>
              </div>
              <div>
                <span>{{ $t('corporateTicket.WhetherToUse') }} ：</span>
                <span>
                  {{
                    ((bookingTicketTypeDetail.toothbrush && '歯ブラシ') || '') +
                      ((bookingTicketTypeDetail.razor && '/髭剃り') || '') +
                      ((bookingTicketTypeDetail.nightwear && '/ナイトウェア') ||
                        '') +
                      ((bookingTicketTypeDetail.rentalItem &&
                        '/貸出品利用可能') ||
                        '')
                  }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col :cols="3" class="d-flex justify-center">
            <div class="table-item">
              <div>
                <span>{{ $t('corporateTicket.UsageAmount') }} ：</span>
                <tvos-jpy
                  v-if="bookingTicketTypeDetail.accommodationPrice"
                  :value="bookingTicketTypeDetail.accommodationPrice"
                />
                <span v-else>無料</span>
              </div>
              <div>
                <span>{{ $t('corporateTicket.ReservationRoute') }} ：</span>
                <span>{{
                  ((bookingTicketTypeDetail.bookCallCenter &&
                    'コールセンター') ||
                    '') +
                    ((bookingTicketTypeDetail.bookSalesRep && '/営業') || '')
                }}</span>
              </div>
              <div>
                <span>{{ $t('corporateTicket.ExpirationDate') }} ：</span>
                <span
                  >宿泊日{{ bookingTicketTypeDetail.cancelDeadline }}日前</span
                >
              </div>
              <div>
                <span>{{ $t('corporateTicket.ReservationStartDate') }} ：</span>
                <template v-if="bookingTicketTypeDetail.bookingFromDate">
                  <span>{{
                    bookingTicketTypeDetail.bookingFromDate.split('-').join('/')
                  }}</span>
                </template>
              </div>
              <div>
                <span>{{ $t('corporateTicket.ReservationPeriod') }} ：</span>
                <ReservationPeriod :data="bookingTicketTypeDetail" />
              </div>
              <div>
                <span
                  >{{ $t('corporateTicket.RightSettingFixedAmount') }} ：</span
                >
                <tvos-jpy :value="bookingTicketTypeDetail.assetValue" />
              </div>
              <div>
                <span>{{ $t('corporateTicket.NumberOfSheetsUsed') }} ：</span>
                <span>
                  {{
                    bookingTicketTypeDetail.computed &&
                      bookingTicketTypeDetail.computed.usedCount
                  }}
                </span>
              </div>
              <div>
                <span
                  >{{ $t('corporateTicket.AvailableNumberOfSheets') }} ：</span
                >
                <span>
                  {{
                    bookingTicketTypeDetail.computed &&
                      bookingTicketTypeDetail.computed.availableCount
                  }}
                </span>
              </div>
              <div>
                <span>{{ $t('corporateTicket.remarks') }}：</span>
                <span> {{ bookingTicketTypeDetail.notes }} </span>
              </div>
            </div>
          </v-col>
          <v-col :cols="2" class="d-flex justify-center">
            <div>
              <div class="table-item">
                <div>
                  <span>{{ $t('corporateTicket.dateRange') }} ：</span>
                  <template v-if="bookingTicketTypeDetail.createdAt">
                    <span>
                      {{
                        bookingTicketTypeDetail.createdAt | formatDate
                      }}
                    </span>
                  </template>
                </div>
                <div>
                  <span>{{ $t('corporateTicket.ExpirationaDte') }} ：</span>
                  <template v-if="bookingTicketTypeDetail.availableToDate">
                    <span>
                      {{
                        bookingTicketTypeDetail.availableToDate | formatDate
                      }}
                    </span>
                  </template>
                </div>
                <div>
                  <span>{{ $t('corporateTicket.lastUpdate') }} ：</span>
                  <span>
                    {{
                      bookingTicketTypeDetail.createdBy &&
                        bookingTicketTypeDetail.createdBy.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="d-flex flex-row-reverse">
          <v-btn
            @click="bookingTicketCsv(bookingTicketTypeDetail.id)"
            small
            class="btn-crm-primary mt-4 mb-1"
            color="#1873D9"
            >チケット番号をCSV出力</v-btn
          >
        </div>
        <v-card flat outlined class="">
          <div>
            <Table
              :attr="{
                dense: true,
                'no-data-text': $t('rules.noData'),
                'item-key': 'id',
                class: 'table-custom',
              }"
              ref="table"
              :headers="header"
              :items="bookingTicketTypeDetail.bookingTickets"
              :disablePagination="true"
              :disableSort="true"
              :hideFooter="true"
            >
              <template v-slot:item.detail="{ item }">
                <v-btn
                  v-if="item.id < 10"
                  color="var(--bt__blue-detail)"
                  class="detail_btn"
                  to="/invitations-and-coupons-detail"
                  >{{ $t('buttons.detail') }}</v-btn
                >
              </template>
              <template v-slot:item.representativeName="{ item }">
                {{
                  item.booking &&
                    item.booking.representative &&
                    item.booking.representative.name
                }}
              </template>
              <template v-slot:item.applicationDate="{ item }">
                <template v-if="item.booking">
                  {{  item.booking.createdAt | formatDate }}
                </template>
              </template>
              <template v-slot:item.facilityName="{item}">
                <template v-if="item.booking">
                  {{  getFacilityNameForTicket(item) }}
                </template>
              </template>
              <template v-slot:item.checkInDate="{item}">
                <template v-if="item.booking">
                  {{  item.booking.checkInDate | formatDate }}
                </template>
              </template>
              <template v-slot:item.bookingStatus="{item}">
                <template v-if="item.booking && item.booking.computed">
                  {{  item.booking.computed.status }}
                </template>
              </template>
              <template v-slot:item.enabledOrDisable="{ item }">
                <v-select
                  :disabled="!checkUser"
                  v-if="!item.isUsed"
                  dense
                  :items="statusOptions"
                  item-text="text"
                  item-value="value"
                  v-model="item.disabled"
                  @change="changeStatus(item.id, item.disabled)"
                  outlined
                  placeholder="来店"
                  hide-details
                  style="width: 85px"
                  class="v-select-custom"
                ></v-select>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  :disabled="!checkUser"
                  @click="actionNewTab(item)"
                  outlined
                  small
                  v-if="item.isAvailable"
                  color="var(--text_gray)"
                  :class="{ 'bg--secondary': !checkUser }"
                  class="t-btn--prm"
                  hide-details
                  >新規予約</v-btn
                >
                <v-btn
                  outlined
                  small
                  v-else
                  color="var(--text_gray)"
                  class="btn-crm-primary"
                  hide-details
                  :disabled="!item.bookingId"
                  @click="redirectPMS(item)"
                  >予約詳細</v-btn
                >
              </template>
            </Table>
          </div>
        </v-card>
      </div>
    </div>
    <ticket-detail
      :showDialog="isShowTicketDetail"
      :id="id"
      @close="isShowTicketDetail = false"
    ></ticket-detail>
  </v-dialog>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions';
import TicketDetail from './ticketDetail.vue';
import { currency2 } from '@/utils/filters.js';
import { mapActions, mapGetters } from 'vuex';
import Table from '@/components/Table/index.vue';
import ReservationPeriod from './ReservationPeriod'

export default {
  components: { TicketDetail, Table, ReservationPeriod },
  name: 'CorporateTicketDetail',
  data() {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      isShowTicketDetail: false,
      id: '',
      selected: [],
      statusOptions: [
        { text: '無効', value: true },
        { text: '有効', value: false },
      ],
      header: [
        {
          text: this.$t('corporateTicket.numberTicket'),
          value: 'id',
        },
        {
          text: this.$t('corporateTicket.AuthenticationNumber'),
          value: 'secret',
        },
        {
          text: this.$t('corporateTicket.applicationDate'),
          value: 'applicationDate',
        },
        {
          text: this.$t('corporateTicket.RepresentativeName'),
          value: 'representativeName',
        },
        {
          text: '宿泊施設',
          value: 'facilityName',
        },
        {
          text: '宿泊開始日',
          value: 'checkInDate',
        },
        {
          text: 'ステータス',
          value: 'bookingStatus',
        },
        {
          text: '有効/無効',
          value: 'enabledOrDisable',
          width: '90px',
        },
        { text: '', value: 'action', align: 'end' },
      ],
    };
  },
  props: {
    visible: Boolean,
    title: String,
  },
  computed: {
    ...mapGetters(['bookingTicketTypeDetail']),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    ...mapActions(['disableBookingTicket', 'bookingTicketCsv']),
    getFacilityNameForTicket(item) {
      return item?.booking?.room?.roomType?.facility?.name
    },
    currency2,
    showTicketDetail() {
      this.isShowTicketDetail = true;
      this.id = this.bookingTicketTypeDetail.id;
    },
    removeLastCharacter(str, character) {
      return str.slice(0, str.lastIndexOf(character));
    },
    actionNewTab(item) {
      const routeData =
        '/corporate-ticket/create-booking?id=' +
        this.bookingTicketTypeDetail.id +
        '&ticketId=' +
        item.id;
      window.open(routeData, '_blank');
    },

    closeDialog() {
      this.$emit('close');
    },
    getFacilityName(roomTypeList) {
      const arrayId = [];
      const arrName = [];
      for (let i = 0; i < roomTypeList.length; i++) {
        if (!arrayId.includes(roomTypeList[i].roomType.facility.id)) {
          arrayId.push(roomTypeList[i].roomType.facility.id);
          arrName.push(roomTypeList[i].roomType.facility.name);
        }
      }
      return arrName.join(' ,');
    },
    async changeStatus(id, status) {
      await this.disableBookingTicket({ ticketId: id, disabled: status });
      this.$emit('changeStatus');
    },
    redirectPMS(item) {
      if (item.booking) {
        const url = `${process.env.VUE_APP_URL_PMS}/reservation/contract-detail?id=${item.booking.id}&tab=basic-information&fromPage=crm`;
        window.open(url, '_blank');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}
::v-deep {
  .v-dialog {
    background-color: var(--bg_color_f8f8f8);
    max-width: 1500px;
  }
  .v-input {
    margin-top: 0;
    &.w-50 {
      width: 50%;
      max-width: 50%;
    }
    &.sort-input {
      width: 180px;
    }
    &.date-text input {
      font-size: 12px;
    }
  }
  label:not(.normal):not(.v-label) {
    font-size: 10px;
  }
}
div.table-item {
  display: table;
  div {
    display: table-row;
    margin: 10px 0;
    span {
      display: table-cell;
      color: #000000;
      font-weight: 500;
      font-size: 14px;
      &:first-child {
        text-align: right;
        min-width: 116px;
      }
    }
  }
}
::v-deep {
  .v-data-table-header {
    tr {
      th {
        color: #000000 !important;
        font-size: 12px !important;
        font-weight: 500;
      }
    }
  }

  .table-custom:not(.--multi-colors) tbody tr td {
    color: #000000 !important;
    font-size: 16px !important;
  }

  .btn-crm-primary {
    color: #1873d9 !important;
  }
  .t-btn--prm {
    background-color: #1873d9 !important;
  }
}
</style>
