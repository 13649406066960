<template>
  <v-dialog v-model="showDialog" @click:outside="closeDialog">
    <v-card class="pb-16 card-cus">
      <h2 class="page-title-list mx-5 my-5">チケット詳細</h2>
      <v-card-text>
        <v-row class="px-10 pt-5 pb-0">
          <v-col :cols="4" class="pr-8 list-item">
            <div class="mb-4">
              <v-text-field
                v-model="bookingTicketTypeList.name"
                label="名称"
                value="データテスト"
                readonly
              ></v-text-field>
            </div>
            <div class="mb-4" v-if="bookingTicketTypeList.computed">
              <v-text-field
                v-model="bookingTicketTypeList.computed.issuedCount"
                label="枚数"
                value="データテスト"
                readonly
              ></v-text-field>
            </div>
            <div class="mb-4">
              <v-select
                :value="facilitySelect"
                :items="arrRoomType"
                multiple
                label="施設"
                item-text="facilityName"
                item-value="facilityId"
                readonly
              ></v-select>
            </div>
            <div class="mb-4">
              <v-select
                :value="roomTypeSelect"
                label="部屋タイプ"
                :items="arrRoomType"
                multiple
                item-text="roomTypeName"
                item-value="roomTypeId"
                readonly
              ></v-select>
            </div>
          </v-col>
          <v-col :cols="4" class="list-item">
            <div class="mb-5">
              <span class="card-cus_tt">日付</span>
              <div class="d-flex align-center mt-1">
                <v-icon class="mr-1">mdi-calendar-month</v-icon>
                <div
                  class="d-flex align-center mt-0"
                >
                  <v-menu
                    disabled
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          style="width: 135px"
                          outlined
                          v-on="on"
                          small
                          class="bg-white--btn"
                          >{{ formatDate(bookingTicketTypeList.availableFromDate) }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>

                  <span class="pl-1 pr-1">〜</span>

                  <v-menu
                    disabled
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <div class="custom-btn-picker">
                        <v-btn
                          style="width: 135px"
                          outlined
                          v-on="on"
                          small
                          class="bg-white--btn mr-5"
                          >{{ formatDate(bookingTicketTypeList.availableToDate) }}</v-btn
                        >
                      </div>
                    </template>
                    <v-date-picker
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
            <div class="mb-5">
              <span class="card-cus_tt">予約経路</span>
              <v-checkbox v-model="bookingTicketTypeList.bookCallCenter" readonly dense hide-details label="コールセンター" />
              <v-checkbox v-model="bookingTicketTypeList.bookSalesRep" readonly dense hide-details label="営業" />
            </div>
            <div class="mb-5">
              <span class="card-cus_tt">曜日</span>
              <div class="d-flex card-cus_chkbox">
                <v-checkbox
                  v-model="bookingTicketTypeList.availableMonday"
                  readonly
                  hide-details
                  :label="$t('weekdays.monday')"
                />
                <v-checkbox
                  v-model="bookingTicketTypeList.availableTuesday"
                  readonly
                  hide-details
                  :label="$t('weekdays.tuesday')"
                />
                <v-checkbox
                  v-model="bookingTicketTypeList.availableWednesday"
                  readonly
                  hide-details
                  :label="$t('weekdays.wednesday')"
                />
                <v-checkbox
                  v-model="bookingTicketTypeList.availableThursday"
                  readonly
                  hide-details
                  :label="$t('weekdays.thursday')"
                />
              </div>
              <div class="d-flex card-cus_chkbox">
                <v-checkbox
                  v-model="bookingTicketTypeList.availableFriday"
                  readonly
                  hide-details
                  :label="$t('weekdays.friday')"
                />
                <v-checkbox
                  v-model="bookingTicketTypeList.availableSaturday"
                  readonly
                  hide-details
                  :label="$t('weekdays.saturday')"
                />
                <v-checkbox
                  v-model="bookingTicketTypeList.availableSunday"
                  readonly
                  hide-details
                  :label="$t('weekdays.sunday')"
                />
                <v-checkbox
                  v-model="bookingTicketTypeList.availableHoliday"
                  readonly
                  hide-details
                  :label="$t('weekdays.holidays')"
                />
              </div>
            </div>
            <div class="mb-3">
              <span class="card-cus_tt">除外日</span>
              <div class="d-flex align-center mt-1">
                <v-icon class="mr-1">mdi-calendar-month</v-icon>
                <div
                  class="d-flex align-center mt-0 w-85"
                  v-for="item in ChangeOfDay"
                  :key="item.id"
                >
                  <v-menu
                    readonly
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="ArrExceptDates"
                        v-on="on"
                        small
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
            <div class="mb-5">
              <span class="card-cus_tt">利用有無</span>
              <v-checkbox v-model="bookingTicketTypeList.nightwear" readonly dense hide-details label="ナイトウェア" />
              <v-checkbox v-model="bookingTicketTypeList.toothbrush" readonly dense hide-details label="歯ブラシ" />
              <v-checkbox v-model="bookingTicketTypeList.razor" readonly dense hide-details label="髭剃り" />
              <v-checkbox v-model="bookingTicketTypeList.rentalItem" readonly dense hide-details label="貸出品利用可能" />
            </div>
          </v-col>
          <v-col :cols="4" class="list-item">
            <div class="mb-5">
              <span class="card-cus_tt">利用金額</span>
              <v-checkbox v-model="statusFree" readonly dense hide-details label="無料" />
              <div class="d-flex justify-space-between">
                <v-checkbox
                  class="custom-checkBox"
                  v-model="statusFree1"
                  readonly
                  dense
                  hide-details
                  label="固定料金 (税込)"
                />
                <template v-if="statusFree1">
                  <v-text-field
                  class="text-right"
                    readonly
                    :value="accommodationPriceInput()"
                  >
                  </v-text-field>
                </template>
                  <v-text-field
                  class="text-right"
                  reverse
                  readonly
                  value="¥0"
                  small
                  v-else
                ></v-text-field>
              </div>
            </div>
            <div>
              <v-select
                readonly
                label="キャンセルポリシー"
                v-model="bookingTicketTypeList.cancelDeadline"
                :items="itemCancelDate"
                item-text="name"
                item-value="value"
              ></v-select>
            </div>
            <div class="mb-5">
              <span class="card-cus_tt">予約開始日</span>
              <div class="d-flex align-center mt-1">
                <v-icon class="mr-1">mdi-calendar-month</v-icon>
                <div
                  class="d-flex align-center mt-0 w-100"
                >
                  <v-menu
                    disabled
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        readonly
                        v-model="bookingTicketTypeList.bookingFromDate"
                        v-on="on"
                        small
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :first-day-of-week="0"
                      :locale="$i18n.locale"
                      scrollable
                      class="v-date-picker-custom"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
            </div>
            <div>
              <span style="margin: 0px" class="headerCustome card-cus_tt">予約可能期間</span>
              <br>
              <div class="text-right"><ReservationPeriod :data="bookingTicketTypeList" /></div>
            </div>
            <div class="mt-2">
              <span style="margin: 0px" class="headerCustome card-cus_tt">権利設定額</span>
              <br />
              <template v-if="bookingTicketTypeList.assetValue">
                <v-text-field
                class="text-right"
                  readonly
                  :value="assetValueInput()"
                >
                </v-text-field>
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row class="px-10 list-item mt-5">
          <div style="width: 100%">
            <span class="card-cus_tt">{{ $t('common.remark') }}</span>
            <v-text-field @change="changeNotes($event)" v-model="bookingTicketTypeList.notes" dense />
          </div>
        </v-row>
      </v-card-text>
      <div class="d-flex justify-end mr-13">
        <v-btn
          color="#C92D2D"
          class="mr-3 d-flex align-center btn_status white--text"
          @click="closeDialog"
        >
          <v-icon class="white--text"> mdi-close </v-icon>
          {{ $t('common.close') }}
        </v-btn>
        <v-btn class="white--text" color="#1873D9" @click="updateBookingTicketType()">
          {{ $t('common.save') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { BOOKING_TICKET_TYPE, UPDATE_BOOKING_TICKET_TYPE } from '@/api/graphql/ticketDetail/ticket-detail'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import gql from 'graphql-tag'
import ReservationPeriod from './ReservationPeriod'

export default {
  name: 'ticketDetail',
  components: { ReservationPeriod },
  props: {
    id: String,
    showDialog: Boolean
  },

  data () {
    return {
      notes: '',
      itemCancelDate: [],
      statusFree: null,
      statusFree1: null,
      disabled: null,
      ArrExceptDates: [],
      facilitySelect: [],
      roomTypeSelect: [],
      arrRoomType: [],
      bookingTicketTypeList: [],
      menuDate: [],
      changeDate: [],
      ReservationStartDate: [],
      listDate: [
        { id: 1, date: new Date().toISOString().substr(0, 10) },
        { id: 2, date: new Date().toISOString().substr(0, 10) }
      ],
      ChangeOfDay: [{ id: 1, date: new Date().toISOString().substr(0, 10) }],
      listReservationStartDate: [
        { id: 1, date: new Date().toISOString().substr(0, 10) }
      ]
    }
  },

  watch: {
    id () {
      this.getBookingTicketType()
    },

    showDialog () {
      this.getBookingTicketType()
    }
  },

  mounted () {
    this.getlistItem()
  },

  methods: {
    ...mapMutations(['setCopyErrorText', 'setLoadingOverlayShow', 'setLoadingOverlayHide', 'setAlertSuccess', 'setAlertError', 'setPermissionUser', 'setRoleAdminUser']),
    ...mapActions(['fetchBookingTicketType']),
    handlErrorView,
    getCopyErrorTextView,

    async updateBookingTicketType () {
      this.setLoadingOverlayShow()
      const variables = {
            id: this.id,
            notes: this.notes
          }
      await this.$apollo
        .mutate({
          mutation: gql`${UPDATE_BOOKING_TICKET_TYPE}`,
          variables: variables
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.setAlertSuccess(this.$t('messages.successfulUpdate'))
          this.fetchBookingTicketType(this.id)
          this.closeDialog()
        })
        .catch(async (error) => {
          this.notes = this.bookingTicketTypeList.notes
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_BOOKING_TICKET_TYPE, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
          this.closeDialog()
        })
    },

    async getBookingTicketType () {
      const variables = {
            id: this.id
          }
      await this.$apollo
        .query({
          query: gql`${BOOKING_TICKET_TYPE}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.bookingTicketTypeList = data.data.bookingTicketType
          this.getValueRoomType()
          this.ArrExceptDates = []
          for (let i = 0; i < this.bookingTicketTypeList.exceptDates.length; i++) {
            this.ArrExceptDates.push(this.bookingTicketTypeList.exceptDates[i].exceptDate)
          }
          this.checkValueFree()
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_TICKET_TYPE, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },

    changeNotes (event) {
      this.notes = event
    },

    assetValueInput () {
      return this.bookingTicketTypeList.assetValue.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
    },

    accommodationPriceInput () {
      return this.bookingTicketTypeList.accommodationPrice.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
    },

    getlistItem () {
      for (let j = 1; j < 200; j++) {
        const item = {
          name: '宿泊日から' + j + '日前以降',
          value: j
        }
        this.itemCancelDate.push(item)
      }
    },

    checkValueFree () {
      if (this.bookingTicketTypeList.accommodationPrice > 0) {
        this.statusFree = false
        this.statusFree1 = true
      } else {
        this.statusFree = true
        this.statusFree1 = false
      }
    },

    getValueRoomType () {
      this.facilitySelect = []
      this.roomTypeSelect = []
      this.arrRoomType = []

      for (let i = 0; i < this.bookingTicketTypeList.roomTypes.length; i++) {
        const data = {
          roomTypeId: this.bookingTicketTypeList.roomTypes[i].roomType.id,
          roomTypeName: this.bookingTicketTypeList.roomTypes[i].roomType.name,
          facilityId: this.bookingTicketTypeList.roomTypes[i].roomType.facility.id,
          facilityName: this.bookingTicketTypeList.roomTypes[i].roomType.facility.name
        }
        if (!this.facilitySelect.includes(this.bookingTicketTypeList.roomTypes[i].roomType.facility.id)) {
        this.facilitySelect.push(this.bookingTicketTypeList.roomTypes[i].roomType.facility.id)
        }
        this.roomTypeSelect.push(this.bookingTicketTypeList.roomTypes[i].roomType.id)
        this.arrRoomType.push(data)
      }
    },

    formatDate (value) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      if (moment(value) > moment(this.dateTo)) {
        this.dateTo = value
      }
      return moment(value).format('yyyy年MM月DD日 (' + d + ')')
    },

    closeDialog () {
      this.$emit('close')
    },
    formatString (arr) {
      var str = ''
      for (var index = 0; index < arr.length; index++) {
        str += str === '' ? arr[index] : '/' + arr[index]
      }
      return str
    }
  }

}
</script>

<style scoped lang="scss">
::v-deep {
  .v-dialog {
    background-color: var(--bg_white);
    max-width: 1200px;
  }
  .sort-select {
    max-width: 180px;
  }
  .v-chip {
    width: 100%;
    color: var(--text_white);
    justify-content: center;
  }
  .custom-checkBox .theme--light {
    width: 120px !important;
  }
  .card-cus {
    box-shadow: none !important;
    .v-label,
    &_tt {
      color: #212121 !important;
    }
    &_chkbox {
      .v-input__slot {
        margin-right: 20px;
      }
    }
    .v-text-field {
      margin-top: 0;
      padding-top: 0;
    }
    .text-right {
      .v-text-field__slot input {
        text-align: right;
      }
    }
    .w-85 {
      width: 85%;
    }
  }
}
.headerCustome {
  font-weight: 500;
}
</style>
