export const BOOKING_TICKET_TYPE = `
query($id: String!){
  bookingTicketType(id:$id){
    id
    name
    intendedClient {
      id
      name
      nameKana
      telPreference
      email
      subMembers{
        name
        nameKana
        tel
        email
        address1
      }
    }
    roomTypes {
      roomType {
        id
        name 
        facility {
          id
          name
        }
      }
    }
    availableFromDate 
    availableToDate 
    exceptDates {
      exceptDate
    }
    toothbrush razor nightwear rentalItem 
    accommodationPrice 
    bookCallCenter bookSalesRep 
    cancelDeadline
    bookingFromDate 
    bookingFromMonths
    bookingFromDays
    availableMonday
    availableTuesday
    availableWednesday
    availableThursday
    availableFriday
    availableSaturday
    availableSunday
    availableHoliday
    assetValue
    notes
    createdAt
    availableToDate
    
    computed {
      issuedCount
      usedCount
      availableCount
    }
  }
}
`

export const UPDATE_BOOKING_TICKET_TYPE = `
mutation(
  $id: String!
  $notes: String!
) {
  updateBookingTicketType(id: $id, notes: $notes) {
    __typename
  }
}
`

export const CLIENT_MEMBER_INFO = `
query ($id: Int!) {
  clientMemberInfo(id: $id) {
    id
    name
    nameKana
    telPreference
    email
    subMembers{
      locality
      postalCode
      id
      name
      nameKana
      tel
      email
      prefecture
      locality
      address1
      address2
    }
  }
}
  `

export const CREATE_BOOKING_V4 = `
  mutation (
    $representativeName: String
    $representativeKana: String
    $representativeTel: String
    $representativeEmail: String
    $representativeCountry: String
    $representativePostalCode: String
    $representativeAddress1: String
    $ticketId: String
    $checkInDate: DateString!
    $roomTypeId: Int!
    $days: [BookingDayInput!]!
    $numberOfAdults: Int
    $numberOfChildren: Int
    $numberOfChildrenWithBedShare: Int
    $bookingBarNote: String
    $parkings: [AddReservationFacilityParkingInput!]
  ){
    createBookingV4(
      bookingTypeId: 4,
      representativeName: $representativeName
      representativeKana: $representativeKana
      representativeTel: $representativeTel
      representativeEmail: $representativeEmail
      representativeCountry: $representativeCountry
      representativePostalCode: $representativePostalCode
      representativeAddress1: $representativeAddress1
      ticketId: $ticketId
      checkInDate: $checkInDate
      roomTypeId: $roomTypeId
      days: $days
      numberOfAdults: $numberOfAdults
      numberOfChildren: $numberOfChildren
      numberOfChildrenWithBedShare: $numberOfChildrenWithBedShare
      parkings: $parkings
      bookingBarNote: $bookingBarNote
    ) {
      id
    }
  }
  `
